@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spinner-fade {
  from {
    background-color: gray;
  }
  to {
    background-color: transparent;
  }
}

@keyframes anim-circle-1 {
  from {
    transform: rotate(60deg);
  }
  to {
    transform: rotate(205deg);
  }
}

@keyframes anim-circle-2 {
  from {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(-115deg);
  }
}

@keyframes anim-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body > section {
  height: 100vh;
  overflow: auto;
}

section.has-footer {
  padding-bottom: 70px;
}

section.bg-grey {
  background-color: #cccccc;
}

#brandList .media + .media {
  padding-top: 0;
  border-top: none;
}

#brandList .media .tag {
  height: 1.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

#brandList .media .tag:not(:last-child) {
  margin-right: 0.25em;
}

#actionHistory .history + .history {
  border-top: 1px solid #bbbbbb;
  padding-top: 12px;
}

#actionHistory .history-general {
  margin-bottom: 0;
}

#actionHistory .history-general p {
  font-weight: 700;
}

#actionHistory .history-detail {
  position: relative;
  background-color: #bbbbbb;
  font-size: 12px;
  padding: 12px;
}

#actionHistory .history-detail .arrow-up {
  position: absolute;
  top: -8px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #bbbbbb;
}

#dineIn .qrcode-button {
  width: 40%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 12px auto;
}

#dineIn .suggestion {
  margin: 24px auto;
}

.order-status {
  background-color: white;
}

.order-status p {
  font-size: 0.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.order-status .row-section .level-left {
  flex-shrink: 1;
}

.order-status .row-section .control {
  margin-top: 0;
}

.order-status .row-section .control:not(:last-child) {
  margin-right: 8px;
}

.order-status .row-section .control .button {
  margin-top: 0;
}

.order-status .order-text {
  padding-right: 5px;
}

.order-status .order-preparation .control:first-child,
.order-status .order-ready .control:first-child {
  margin-right: 0;
}

.order-status .order-preparation .bridge,
.order-status .order-ready .bridge {
  width: 8px;
  height: 27px;
  padding: 11px 0;
  margin-top: -13.5px;
  transform: translateY(50%);
}

.order-status .order-preparation .bridge .line,
.order-status .order-ready .bridge .line {
  width: 8px;
  height: 5px;
  background-color: #ff8800;
}

.order-status .order-pending,
.order-status .order-preparing {
  background-color: #e6e6e6;
  border: 1px solid #ff8000;
  text-transform: none;
  opacity: 1;
}

.order-status .order-choped,
.order-status .order-confirmed,
.order-status .order-makan-time {
  background-color: #ff8000;
  color: white;
  border: none;
  text-transform: none;
  opacity: 1;
}

.order-details {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.thank-you {
  margin-top: 12px;
  margin-bottom: 24px;
}

.thank-you h5 {
  font-size: 1rem;
  font-weight: 900;
}

.thank-you p {
  font-weight: 700;
}

.thank-you p.has-text-dark {
  margin-bottom: 24px;
}

.thank-you hr {
  width: 120px;
  height: 1px;
  background-color: #cccccc;
  margin-right: auto;
  margin-left: auto;
}

.main .media:first-child {
  margin-top: -8px;
}

.column.bg-grey {
  background-color: #cccccc;
}

.column.no-wrap {
  padding: 0 7px;
  margin: 12px 0;
  white-space: nowrap;
  overflow: auto;
}

.column .field strong {
  text-transform: uppercase;
}

.parent-categories .parent-category {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
}

.parent-categories .parent-category .category-bg {
  width: 96px;
  height: 64px;
  color: white;
  text-align: center;
  line-height: 30px;
  padding: 10px 0;
  border: 1px solid gray;
  border-radius: 4px;
}

.categories .button {
  margin-right: 5px;
  margin-left: 5px;
}

.button {
  font-weight: 500;
  text-transform: uppercase;
}

.logo {
  width: 100%;
  height: 110px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.media .media-left .image {
  width: 120px;
  background-color: white;
}

.media .media-content .content .top-half {
  min-height: 60px;
  margin-bottom: 0;
}

.media .media-content .content .top-half.product {
  min-height: 70px;
}

.modal-card-body .button {
  margin-top: 10px;
}

.modal-header {
  font-weight: 900;
  color: #e22222;
  text-align: center;
  text-transform: uppercase;
}

.modal-header .icon {
  border: 2px solid;
  border-radius: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.modal-body {
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
}

.row-section.modifier {
  padding-bottom: 12px;
}

.row-section.order {
  margin-top: 12px;
}

.row-section + .row-section {
  padding-top: 12px;
  border-top: 1px solid #cccccc;
  margin-top: 12px;
}

.row-section .control {
  margin-top: 5px;
}

.row-section .control .button {
  margin-top: 10px;
}

.modifier strong {
  text-transform: uppercase;
}

.modifier .columns {
  padding-right: 6px;
  padding-left: 6px;
  margin-top: 0;
}

.modifier .columns .column {
  padding: 6px;
}

.modifier .columns .column.is-flex {
  flex: auto;
  width: 100%;
}

.modifier .columns .column .button {
  display: block;
  width: 100%;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quantity-button {
  display: flex;
  flex: 1 1 30%;
}

.quantity-button.on-right {
  margin-left: 6px;
}

.quantity-button.on-left {
  margin-right: 6px;
}

.quantity-button .button {
  flex: 1 1 30%;
  padding: 5px;
}

.quantity-button > p {
  line-height: 1.15;
}

.quantity-button > strong {
  flex: 2 1 30%;
  text-align: center;
  line-height: 36px;
}

.quantity-part {
  flex: 1 1 70%;
}

.quantity-part.on-right {
  margin-left: 6px;
}

.quantity-part.on-left {
  margin-right: 6px;
}

.strike-separator {
  display: flex;
  width: 70%;
  margin: 24px auto;
}

.strike-separator .strike-line {
  flex-grow: 1;
  height: 10px;
  border-bottom: 1px solid #cccccc;
}

.strike-separator .strike-text {
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
}

.footer {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #cccccc;
  padding: 12px;
}

.footer .quantity-button .button {
  background-color: #a6a6a6;
  color: #e22222;
}

.fake-select-box {
  width: 100%;
}

.fake-select-text {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.jconfirm .jconfirm-holder {
  padding: 15px;
}

.jconfirm .jconfirm-holder .jconfirm-box {
  border-radius: 0;
}

#paymentMethods header img {
  height: 40px;
  margin: 15px 10px 0;
}

#paymentMethods header .brand {
  font-size: 1.2rem;
}

#paymentMethods .payment-title {
  margin-bottom: 12px;
}

#paymentMethods .payment {
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #cccccc;
}

#paymentMethods .payment.selected {
  background-color: #e6e6e6;
  border: 2px solid #e22222;
}

#paymentMethods .payment.selected strong {
  color: #e22222;
}

#paymentMethods .payment img {
  max-width: 80px;
  max-height: 40px;
}

#paymentMethods .payment .level:not(:last-child) {
  margin-bottom: 8px;
}

#paymentMethods .payment .level + .level {
  border-top: 1px solid #cccccc;
  padding-top: 8px;
  margin-left: 25px;
}

#paymentMethods .payment .level.is-mobile .level-item {
  justify-content: flex-start;
  margin-right: 0;
}

#paymentMethods .payment .level.is-mobile .radio-button {
  flex-grow: 0;
  width: 25px;
}

#paymentMethods .payment .level.is-mobile .payment-image {
  flex-grow: 0;
  width: 95px;
}

#paymentMethods .paylah .mobile-number .is-left {
  width: 50%;
  padding-right: 4px;
}

#paymentMethods .paylah .mobile-number .is-right {
  width: 50%;
  padding-left: 4px;
}

#paymentMethods .tnc {
  margin-top: 25px;
  text-align: center;
}

#paymentMethods .tnc .link {
  color: #e22222;
}

.order-checkout {
  max-width: 340px;
  width: 86%;
  padding: 0 20px;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.field > .country-code {
  position: absolute;
  background: #ffffff;
  max-width: 52px;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid #cccccc;
  z-index: 10;
}

span.country-code + input {
  padding-left: 60px;
}

#guestCheckout {
  max-width: 60%;
  margin: auto;
  padding-top: 60px;
}

#guestCheckout h3 {
  padding: 10px 0 10px 0;
  font-weight: 500;
  font-size: 1.35em;
}

.tnc-container {
  width: 100%;
  height: 500px;
}

.spin-loader-ios {
  font-size: 24px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spin-loader-ios.center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.spin-loader-ios .spinner-blade {
  position: absolute;
  left: .4629em;
  bottom: 0;
  width: .074em;
  height: .2777em;
  border-radius: .0555em;
  background-color: transparent;
  transform-origin: center -.2222em;
  animation: spinner-fade 1s infinite linear;
}

.spin-loader-ios .spinner-blade:nth-child(1) {
  animation-delay: 0s;
  transform: rotate(0deg);
}

.spin-loader-ios .spinner-blade:nth-child(2) {
  animation-delay: 0.083s;
  transform: rotate(30deg);
}

.spin-loader-ios .spinner-blade:nth-child(3) {
  animation-delay: 0.166s;
  transform: rotate(60deg);
}

.spin-loader-ios .spinner-blade:nth-child(4) {
  animation-delay: 0.249s;
  transform: rotate(90deg);
}

.spin-loader-ios .spinner-blade:nth-child(5) {
  animation-delay: 0.332s;
  transform: rotate(120deg);
}

.spin-loader-ios .spinner-blade:nth-child(6) {
  animation-delay: 0.415s;
  transform: rotate(150deg);
}

.spin-loader-ios .spinner-blade:nth-child(7) {
  animation-delay: 0.498s;
  transform: rotate(180deg);
}

.spin-loader-ios .spinner-blade:nth-child(8) {
  animation-delay: 0.581s;
  transform: rotate(210deg);
}

.spin-loader-ios .spinner-blade:nth-child(9) {
  animation-delay: 0.664s;
  transform: rotate(240deg);
}

.spin-loader-ios .spinner-blade:nth-child(10) {
  animation-delay: 0.747s;
  transform: rotate(270deg);
}

.spin-loader-ios .spinner-blade:nth-child(11) {
  animation-delay: 0.83s;
  transform: rotate(300deg);
}

.spin-loader-ios .spinner-blade:nth-child(12) {
  animation-delay: 0.913s;
  transform: rotate(330deg);
}

.spin-loader-android {
  font-size: 32px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  animation-name: anim-spinner;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spin-loader-android.center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.spin-loader-android .circle {
  width: 32px;
  height: 16px;
  overflow: hidden;
}

.spin-loader-android .circle-inner {
  transform: rotate(45deg);
  border-radius: 50%;
  border: 4px solid gray;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  width: 100%;
  height: 200%;
  animation-name: anim-circle-1;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
}

.spin-loader-android .circle-2 {
  transform: rotate(180deg);
}

.spin-loader-android .circle-2 .circle-inner {
  animation-name: anim-circle-2;
}

.spin-loader-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background: none;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 5px 0 0 white;
  animation: spinAround 1s linear infinite;
}

.spin-loader-circle.is-dark {
  box-shadow: 0 5px 0 0 gray;
}

@media screen and (max-width: 376px) {
  header,
  main {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 321px) {
  header,
  main {
    font-size: 0.8rem;
  }
}
