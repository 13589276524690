body>section {
  height: 100vh;
  overflow: auto;
}

section {
  &.has-footer {
    padding-bottom: 70px;
  }

  &.bg-grey {
    background-color: $grey-lighter;
  }
}

#brandList {
  .media {
    &+.media {
      padding-top: 0;
      border-top: none;
    }

    .tag {
      &:not(:last-child) {
        margin-right: 0.25em;
      }
      
      height: 1.5em;
      padding-right: 0.5em;
      padding-left: 0.5em;
    }
  }
}

#actionHistory {
  .history {
    &+.history {
      border-top: 1px solid #bbbbbb;
      padding-top: 12px;
    }
  }

  .history-general {
    margin-bottom: 0;
    
    p {
      font-weight: 700;
    }
  }

  .history-detail {
    position: relative;
    background-color: #bbbbbb;
    font-size: 12px;
    padding: 12px;

    .arrow-up {
      position: absolute;
      top: -8px;
      left: 12px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #bbbbbb;
    }
  }
}

#dineIn {
  .qrcode-button {
    width: 40%;
    padding: 10px;
    border: 1px solid $grey-lighter;
    border-radius: 5px;
    margin: 12px auto;
  }

  .suggestion {
    margin: 24px auto;
  }
}

.order-status {
  background-color: $white;

  p {
    font-size: 0.6rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .row-section {
    .level-left {
      flex-shrink: 1;
    }

    .control {
      margin-top: 0;

      &:not(:last-child) {
        margin-right: 8px;
      }

      .button {
        margin-top: 0;
      }
    }
  }

  .order-text {
    padding-right: 5px;
  }

  .order-preparation,
  .order-ready {
    .control {
      &:first-child {
        margin-right: 0;
      }
    }

    .bridge {
      width: 8px;
      height: 27px;
      padding: 11px 0;
      margin-top: -13.5px;
      transform: translateY(50%);

      .line {
        width: 8px;
        height: 5px;
        background-color: #ff8800;
      }
    }
  }

  .order-pending,
  .order-preparing {
    background-color: #e6e6e6;
    border: 1px solid #ff8000;
    text-transform: none;
    opacity: 1;
  }

  .order-choped,
  .order-confirmed,
  .order-makan-time {
    background-color: #ff8000;
    color: white;
    border: none;
    text-transform: none;
    opacity: 1;
  }
}

.order-details {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.thank-you {
  margin-top: 12px;
  margin-bottom: 24px;

  h5 {
    font-size: 1rem;
    font-weight: 900;
  }

  p {
    font-weight: 700;

    &.has-text-dark {
      margin-bottom: 24px;
    }
  }

  hr {
    width: 120px;
    height: 1px;
    background-color: $grey-lighter;
    margin-right: auto;
    margin-left: auto;
  }
}

.main {
  .media {
    &:first-child {
      margin-top: -8px;
    }
  }
}

.column {
  &.bg-grey {
    background-color: $grey-lighter;
  }

  &.no-wrap {
    padding: 0 7px;
    margin: 12px 0;
    white-space: nowrap;
    overflow: auto;
  }

  .field {
    strong {
      text-transform: uppercase;
    }
  }
}

.parent-categories {
  .parent-category {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;

    .category-bg {
      width: 96px;
      height: 64px;
      color: $white;
      text-align: center;
      line-height: 30px;
      padding: 10px 0;
      border: 1px solid $grey;
      border-radius: 4px;
    }
  }
}

.categories {
  .button {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.button {
  font-weight: 500;
  text-transform: uppercase;
}

.logo {
  width: 100%;
  height: 110px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.media {
  .media-left {
    .image {
      width: 120px;
      background-color: $white;
    }
  }

  .media-content {
    .content {
      .top-half {
        min-height: 60px;
        margin-bottom: 0;

        &.product {
          min-height: 70px;
        }
      }
    }
  }
}

.modal-card-body {
  .button {
    margin-top: 10px;
  }
}

.modal-header {
  font-weight: 900;
  color: $primary;
  text-align: center;
  text-transform: uppercase;

  .icon {
    border: 2px solid;
    border-radius: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.modal-body {
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
}

.row-section {
  &.modifier {
    padding-bottom: 12px;
  }

  &.order {
    margin-top: 12px;
  }

  &+.row-section {
    padding-top: 12px;
    border-top: 1px solid $grey-lighter;
    margin-top: 12px;
  }

  .control {
    margin-top: 5px;

    .button {
      margin-top: 10px;
    }
  }
}

.modifier {
  strong {
    text-transform: uppercase;
  }

  .columns {
    padding-right: 6px;
    padding-left: 6px;
    margin-top: 0;

    .column {
      padding: 6px;

      &.is-flex {
        flex: auto;
        width: 100%;
      }

      .button {
        display: block;
        width: 100%;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.quantity-button {
  display: flex;
  flex: 1 1 30%;

  &.on-right {
    margin-left: 6px;
  }
  
  &.on-left {
    margin-right: 6px;
  }

  .button {
    flex: 1 1 30%;
    padding: 5px;
  }

  &>p {
    line-height: 1.15;
  }

  &>strong {
    flex: 2 1 30%;
    text-align: center;
    line-height: 36px;
  }
}

.quantity-part {
  flex: 1 1 70%;

  &.on-right {
    margin-left: 6px;
  }

  &.on-left {
    margin-right: 6px;
  }
}

.strike-separator {
  display: flex;
  width: 70%;
  margin: 24px auto;
  
  .strike-line {
    flex-grow: 1;
    height: 10px;
    border-bottom: 1px solid $grey-lighter
  }

  .strike-text {
    line-height: 20px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.footer {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $grey-lighter;
  padding: 12px;

  .quantity-button {
    .button {
      background-color: $grey-light;
      color: $primary;
    }
  }
}

.fake-select-box {
  width: 100%;
}

.fake-select-text {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.jconfirm {
  .jconfirm-holder {
    padding: 15px;

    .jconfirm-box {
      border-radius: 0;
    }
  }
}

#paymentMethods {
  header {
    img {
      height: 40px;
      margin: 15px 10px 0;
    }

    .brand {
      font-size: 1.2rem;
    }
  }

  .payment-title {
    margin-bottom: 12px;
  }

  .payment {
    padding: 8px;
    margin-bottom: 12px;
    border: 2px solid $grey-lighter;

    &.selected {
      background-color: $white-ter;
      border: 2px solid $primary;

      strong {
        color: $primary;
      }
    }

    img {
      max-width: 80px;
      max-height: 40px;
    }

    .level {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &+.level {
        border-top: 1px solid #cccccc;
        padding-top: 8px;
        margin-left: 25px;
      }

      &.is-mobile {
        .level-item {
          justify-content: flex-start;
          margin-right: 0;
        }

        .radio-button {
          flex-grow: 0;
          width: 25px;
        }

        .payment-image {
          flex-grow: 0;
          width: 95px;
        }
      }
    }
  }

  .paylah {
    .mobile-number {
      .is-left {
        width: 50%;
        padding-right: 4px;
      }

      .is-right {
        width: 50%;
        padding-left: 4px;
      }
    }
  }

  .tnc {
    margin-top: 25px;
    text-align: center;

    .link {
      color: $primary;
    }
  }
}

.order-checkout {
  max-width: 340px;
  width: 86%;
  padding: 0 20px;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.field>.country-code {
  position: absolute;
  background: #ffffff;
  max-width: 52px;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid #cccccc;
  z-index: 10;
}

span.country-code+input {
  padding-left: 60px;
}

#guestCheckout {
  max-width: 60%;
  margin: auto;
  padding-top: 60px;
}

#guestCheckout h3 {
  padding: 10px 0 10px 0;
  font-weight: 500;
  font-size: 1.35em;
}

.tnc-container {
	width: 100%;
	height: 500px;
}

.spin-loader-ios {
  font-size: $spinner-size-ios;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;

  &.center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .spinner-blade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: .074em;
    height: .2777em;
    border-radius: .0555em;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinner-fade 1s infinite linear;
    $animation-delay: 0s;
    $blade-rotation: 0deg;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + 30;
        $animation-delay: $animation-delay + .083;
      }
    }
  }
}

.spin-loader-android {
  font-size: $spinner-size-android;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  animation-name: anim-spinner;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .circle {
    width: $spinner-size-android;
    height: ($spinner-size-android/2);
    overflow: hidden;
  }

  .circle-inner {
    transform: rotate(45deg);
    border-radius: 50%;
    border: ($spinner-size-android/8) solid $spinner-color-android;
    border-right: ($spinner-size-android/8) solid transparent;
    border-bottom: ($spinner-size-android/8) solid transparent;
    width: 100%;
    height: 200%;
    animation-name: anim-circle-1;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.25, .1, .5, 1);
  }

  .circle-2 {
    transform: rotate(180deg);
    & .circle-inner {
      animation-name: anim-circle-2;
    }
  }
}

.spin-loader-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background: none;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 5px 0 0 $white;
  animation: spinAround 1s linear infinite;

  &.is-dark {
    box-shadow: 0 5px 0 0 $grey;
  }
}

@media screen and (max-width: 376px) {
  header,
  main {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 321px) {
  header,
  main {
    font-size: 0.8rem;
  }
}